import * as React from 'react'
import classNames from 'classnames'

import {scrollTo} from '../utils/scroll-to'

const ScrollToButton = ({toRef, duration, className, children}) => {
  const handleClick = () => scrollTo({ref: toRef, duration})

  return (
    <button onClick={handleClick} className={classNames(className)}>
      {children}
    </button>
  )
}

export default ScrollToButton
