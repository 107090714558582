import * as React from 'react'

const Footer = () => (
  <footer className="border-t border-blueDianne pt-11 pb-6 lg:py-20">
    <div className="container flex max-w-[113rem] flex-col-reverse items-center justify-between text-[0.9375rem] font-extralight md:flex-row md:text-[0.9rem] lg:text-[20px]">
      <span className="mt-8 md:mt-0">©2023 Twyn. All Rights Reserved</span>
      <div className="flex flex-col items-center space-y-4 md:flex-row md:space-x-10 md:space-y-0">
        <a href='/about/menu' className="hover-hover:hover:underline">
	  About Twyn
	</a>
        <a href='/episodes/conversations-in-the-twyn-app' className="hover-hover:hover:underline">
	  Conversations
	</a>
	<a href="https://www.instagram.com/twyn_english_speaking_practice/" className="hover-hover:hover:underline">
          Instagram
        </a>
        <a href="https://www.tiktok.com/@twyn_english_practice" className="hover-hover:hover:underline">
          TikTok
        </a>
        <a href="https://www.youtube.com/channel/UCYi5bjAL8gEgbNBCe4uKmyw" className="hover-hover:hover:underline">
          YouTube
        </a>
        <a href="https://www.reddit.com/r/twyn_english_practice/" className="hover-hover:hover:underline">
          Reddit
        </a>
        <a href="https://intercom.help/twyn/en/articles/5178495-privacy-notice" className="hover-hover:hover:underline">
          Privacy Policy
        </a>
        <a href="https://www.mytwyn.com/" className="hover-hover:hover:underline">
          www.mytwyn.com
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
