import * as React from 'react'
import StoryblokClient from 'storyblok-js-client';

import Footer from './footer'
import SubpageHeader from './subpage-header'

const Storyblok = new StoryblokClient({ accessToken: 'm8EobPY5I94Yroz02ux1yQtt' });

const TwynPage = ({ blok }) => {
  const html = Storyblok.richTextResolver.render(blok.content);
  return (
    <>
    <section className="flex">
      <div className="bg-blueDianne text-white relative flex w-full py-6 lg:py-9">
        <div className="container relative flex max-w-[113rem] flex-col">
          <SubpageHeader />
	</div>
      </div>
    </section>
    <section>
      <div class='container py-12 max-w-4xl'>
        <h2>{blok.title}</h2>
      </div>
    </section>
    <section>
      <div class='container text-xl richtext font-extralight py-12 max-w-4xl'>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </section>
    <Footer />
    </>
  )
};
 
export default TwynPage;
