import * as React from 'react'
import {motion} from 'framer-motion'

import { useStoryblok, StoryblokComponent } from '@storyblok/react';

import ScrollToButton from './components/scroll-to-button'
import Section from './components/section'
import Header from './components/header'
import Footer from './components/footer'

import downloadOnAppStore from './images/download-on-app-store.svg'
import getItOnGooglePlay from './images/get-it-on-google-play.svg'
import microphone from './images/microphone.svg'
import iconArrowDown from './images/icon-arrow-down.svg'
import iconAvailableAnytime from './images/icon-available-anytime.svg'
import iconConversationTopics from './images/icon-conversation-topics.svg'
import iconNativeSpeakers from './images/icon-native-speakers.svg'
import iconiconNewConversations from './images/icon-new-conversations.svg'
import phoneFrame from './images/phone-frame.webp'
import bgThreePhones from './images/three-phones.webp'
import lady from './images/lady.webp'
import demoVideo from './videos/demo.mov'

const App = () => {
  React.useEffect(() => {
    mixpanel.track_links('.download-link', 'Download Link Clicked (provisional)'); // eslint-disable-line
  }, [])
  const refWhoIsItFor = React.useRef(null)
  const refFeatures = React.useRef(null)
  const refHowDoesItWork = React.useRef(null)
  const navLinks = [
    {
      title: 'Who is it for?',
      toRef: refWhoIsItFor,
    },
    {
      title: 'Features',
      toRef: refFeatures,
    },
    {
      title: 'How does it work?',
      toRef: refHowDoesItWork,
    },
  ]
  const title = 'A completely new way to practise speaking English'
  const splittedTitle = title.split(' ')
  const resultTitle = splittedTitle.map((word, i) => {
    return word + (i === splittedTitle.length - 1 ? '' : '\u00A0')
  })

  const titleWrapperVariants = {
    before: {transition: {staggerChildren: 0.15}},
    after: {transition: {staggerChildren: 0.25}},
  }
  const wordVariants = {
    before: {
      opacity: 0,
      y: 50,
      transition: {type: 'spring', stiffness: 50, duration: 0.3},
    },
    after: {
      opacity: 1,
      y: 0,
      transition: {type: 'spring', stiffness: 50, duration: 0.2},
    },
  }
  const subscriptionVariants = {
    before: {
      opacity: 0,
      scale: 0.3,
      transition: {type: 'spring', stiffness: 100, duration: 0.7},
    },
    after: {
      opacity: 1,
      scale: 1,
      transition: {type: 'spring', stiffness: 100, duration: 0.6},
    },
  }
  const story = useStoryblok('www-twynenglish-com' + window.location.pathname, { version: 'draft' });
  if (window.location.pathname === '/') {
    return (
      <div>
        <section className="flex min-h-screen">
          <div className="hero-gradient relative flex w-full py-6 lg:py-9">
            <div className="container relative flex max-w-[113rem] flex-col">
              <Header navLinks={navLinks} />
              <div className="flex grow flex-col lg:flex-row">
                <div className="mt-8 flex shrink-0 flex-col md:mt-14 lg:mt-0 lg:max-w-[820px]">
                  <div className="flex grow flex-col justify-center">
                    <motion.div
                      variants={titleWrapperVariants}
                      initial="before"
                      animate="after"
                      exit="after"
                      className="flex max-w-lg flex-wrap font-medium leading-[1.155] lg:text-[3.5rem] xl:max-w-2xl xl:text-[4.5rem] 2xl:min-h-[510px] 2xl:max-w-4xl 2xl:text-[5.625rem]"
                    >
                      {resultTitle.map((word, i) => {
                        return (
                          <motion.div key={i} variants={wordVariants}>
                            {word}
                          </motion.div>
                        )
                      })}
                      <motion.h3
                        variants={subscriptionVariants}
                        className="mt-10 hidden font-extralight leading-[1.375] lg:block lg:max-w-md lg:text-[2rem] xl:max-w-lg xl:text-[2.25rem] 2xl:mt-12 2xl:max-w-2xl"
                      >
                        Don’t just listen to videos in English. In this app you
                        can speak to the videos as well!
                      </motion.h3>
                    </motion.div>
                  </div>
                  <div className="mt-12 hidden shrink-0 lg:block">
                    <ScrollToButton
                      className="block animate-bounce"
                      toRef={refWhoIsItFor}
                      duration={1500}
                    >
                      <img
                        src={iconArrowDown}
                        alt="Arrow Down"
                        className="block w-8"
                      />
                    </ScrollToButton>
                  </div>
                </div>
                <div
                  className="mt-10 flex grow items-end justify-end bg-shamrock bg-contain bg-center bg-no-repeat p-5 md:p-11 lg:mt-0 lg:bg-transparent lg:p-0 xl:pb-12 2xl:pb-20"
                  style={{backgroundImage: `url("${bgThreePhones}")`}}
                >
                  <div className="space-y-2">
                    <a
                      href="https://lse.onelink.me/Hzcj"
                      className="download-link block w-[120px] md:w-32 lg:w-40 xl:w-[12.25rem]"
                    >
                      <img
                        src={downloadOnAppStore}
                        className="block w-full"
                        alt=""
                      />
                    </a>
                    <a
                      href="https://lse.onelink.me/Hzcj"
                      className="download-link block w-[120px] md:w-32 lg:w-40 xl:w-[12.25rem]"
                    >
                      <img
                        src={getItOnGooglePlay}
                        className="block w-full"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="mt-8 flex shrink-0 flex-col items-center lg:hidden">
                  <h3 className="max-w-lg self-end text-right text-[22px] font-extralight md:text-inherit">
                    Don’t just listen to videos in English. In this app you can
                    speak to the videos as well!
                  </h3>
                  <div className="mt-8 shrink-0">
                    <ScrollToButton
                      className="block animate-bounce"
                      toRef={refWhoIsItFor}
                      duration={1500}
                    >
                      <img
                        src={iconArrowDown}
                        alt="Arrow Down"
                        className="block w-6"
                        width="32"
                        height="40"
                      />
                    </ScrollToButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Section
          ref={refWhoIsItFor}
          className="flex min-h-screen flex-col bg-blueDianne bg-cover bg-no-repeat text-white md:min-h-[50vh] md:flex-row md:bg-[#aedce8] md:pt-12 md:text-blueDianne lg:min-h-[65vh] lg:pt-16 xl:min-h-screen xl:pt-20 2xl:pt-24"
        >
          <div className="flex grow items-end bg-[#aedce8] md:hidden">
            <img src={lady} alt="" className="block w-full" />
          </div>
          <div className="container flex shrink-0 justify-end md:max-w-[113rem]">
            <div className="hidden w-[55%] items-end md:flex">
              <img src={lady} alt="" className="block w-full" />
            </div>
            <div className="space-y-4 py-8 text-center md:flex md:w-[45%] md:shrink-0 md:flex-col md:justify-center md:pt-0 md:pb-12 md:text-left lg:space-y-8 lg:pb-16 xl:space-y-10 xl:pb-20 2xl:space-y-12 2xl:pb-24">
              <h2>Who is it for?</h2>
              <h3 className="max-w-2xl text-[25px] font-extralight md:text-[1.5rem] lg:text-[2rem] xl:text-[2.25rem] 2xl:text-[2.5rem]">
                This app is for anyone with intermediate-level English who wants
                to practise speaking.
              </h3>
              <h3 className="max-w-2xl text-[25px] font-extralight md:text-[1.5rem] lg:text-[2rem] xl:text-[2.25rem] 2xl:text-[2.5rem]">
                There are no lessons in the app. It’s all about speaking practice!
              </h3>
            </div>
          </div>
        </Section>
        <Section
          ref={refFeatures}
          className="flex py-14 md:min-h-[50vh] md:py-12 lg:py-16 xl:py-20 2xl:py-24"
        >
          <div className="container flex items-center">
            <div className="grid place-items-center gap-y-10 text-center text-[25px] font-extralight leading-[1.15] md:grid-flow-col md:grid-rows-2 md:gap-y-0 md:gap-x-4 md:text-[1.25rem] lg:gap-x-6 lg:text-[1.75rem] 2xl:text-[2.5rem]">
              <div className="flex flex-col items-center space-y-5">
                <div className="w-14 md:w-[60px] xl:w-[74px] 2xl:w-[86px]">
                  <img src={iconNativeSpeakers} alt="Native English Speakers" />
                </div>
                <h3>NATIVE ENGLISH SPEAKERS</h3>
              </div>
              <div className="flex flex-col items-center space-y-5">
                <div className="w-[60px] md:w-16 xl:w-20 2xl:w-[93px]">
                  <img
                    src={iconiconNewConversations}
                    alt="New Conversations Every Week"
                  />
                </div>
                <h3>NEW CONVERSATIONS EVERY WEEK</h3>
              </div>
              <div className="row-span-2">
                <div className="mx-auto w-[200px] lg:w-[220px] xl:w-[340px] 2xl:w-[380px]">
                  <img
                    src={phoneFrame}
                    alt="Phone Frame"
                    className="relative block w-full"
                  />
                </div>
              </div>
              <div className="flex flex-col items-center space-y-5">
                <div className="w-[60px] md:w-16 xl:w-20 2xl:w-[93px]">
                  <img
                    src={iconConversationTopics}
                    alt="Lots of Different Conversation Topics"
                  />
                </div>
                <h3>LOTS OF DIFFERENT CONVERSATION TOPICS</h3>
              </div>
              <div className="flex flex-col items-center space-y-5">
                <div className="w-[60px] md:w-16 xl:w-20 2xl:w-[93px]">
                  <img src={iconAvailableAnytime} alt="Available Any Time" />
                </div>
                <h3>
                  AVAILABLE
                  <br className="hidden md:block" /> ANY TIME
                </h3>
              </div>
            </div>
          </div>
        </Section>
        <Section
          ref={refHowDoesItWork}
          className="flex bg-blueDianne pt-5 pb-12 lg:py-16 xl:py-20 2xl:py-24"
        >
          <div className="container flex flex-col md:flex-row">
            <div className="shrink-0 md:w-1/3">
              <video autoPlay loop muted playsInline>
                <source src={demoVideo} type="video/mp4" />
              </video>
            </div>
            <div className="flex grow flex-col justify-between text-white md:pl-16 xl:pl-20 2xl:pl-28">
              <div className="w-56 -translate-y-8 self-center md:self-end lg:w-64 xl:w-80 2xl:w-96">
                <img src={microphone} alt="" />
              </div>
              <div>
                <h2 className="w-48 max-w-lg md:w-full">How does it work?</h2>
                <h3 className="mt-3 max-w-[52rem] text-[25px] font-extralight md:mt-6 md:text-[1.5rem] lg:mt-10 lg:text-[2rem] xl:text-[2.25rem] 2xl:mt-12 2xl:text-[2.5rem]">
                  The app uses voice recognition and artificial intelligence. We
                  create realistic English conversations, using videos of real
                  people.
                </h3>
              </div>
            </div>
          </div>
        </Section>
        <section className="py-[70px] md:py-12 lg:py-16 xl:py-20 2xl:py-24">
          <div className="container">
            <div className="grid place-items-center gap-6 md:grid-cols-2">
              <div className="text-center text-[21px] md:max-w-[300px] md:space-y-1 md:text-left md:text-[1.25rem] lg:max-w-none lg:text-[2rem] xl:space-y-2 xl:text-[2.25rem] 2xl:text-[2.5rem]">
                <div className="font-medium">
                  Find out when Twyn launches for other languages.
                </div>
                <div className="font-extralight">
                  Choose the language you’re interested in and leave your email
                  address.
                </div>
              </div>
              <div className="flex justify-center space-x-4 md:space-x-6 lg:space-x-8 xl:space-x-10">
                <a
                  href="https://z5g8gzrk4h3.typeform.com/to/PpNrGCHY"
                  className="flex h-[54px] w-36 items-center justify-center rounded-full bg-sunshade text-[1rem] font-bold uppercase text-white duration-200 md:h-14 md:px-8 md:text-[1.25rem] lg:h-16 lg:w-[200px] lg:text-[18px] xl:h-20 xl:w-[264px] xl:text-[22px] 2xl:h-[84px] hover-hover:hover:bg-orange-600"
                >
                  Spanish
                </a>
                <a
                  href="https://z5g8gzrk4h3.typeform.com/to/Wui7dDnb"
                  className="flex h-[54px] w-36 items-center justify-center rounded-full bg-sunshade text-[1rem] font-bold uppercase text-white duration-200 md:h-14 md:px-8 md:text-[1.25rem] lg:h-16 lg:w-[200px] lg:text-[18px] xl:h-20 xl:w-[264px] xl:text-[22px] 2xl:h-[84px] hover-hover:hover:bg-orange-600"
                >
                  French
                </a>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    )
  }
  else {
    return <StoryblokComponent blok={story.content} />;
  }
}

export default App
