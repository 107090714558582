import * as React from 'react'

import logo from '../images/logo.svg'

const SubpageHeader = () => {
  return (
    <header className="flex shrink-0 justify-between">
      <a
        href="/"
        className="flex items-center space-x-2 md:space-x-3 lg:space-x-5"
      >
        <div className="w-10 md:w-16 xl:w-20">
          <img src={logo} alt="Twyn English Speaking Practice - Realistic Conversations with Video AI" className="block" />
        </div>
        <h1 className="text-[1.75rem] font-medium md:text-[2rem] xl:text-[3rem]">
          Twyn
        </h1>
      </a>
      <div className="flex items-center md:space-x-5 lg:space-x-8 xl:space-x-10 2xl:space-x-12">
        <ul className="hidden items-center md:flex md:space-x-5 lg:space-x-8 xl:space-x-10 2xl:space-x-12">
	  <li class='flex'>
	    <a class='relative text-[0.75rem] font-bold uppercase before:absolute before:-bottom-1 before:h-1 before:w-0 before:bg-blueDianne before:duration-500 hover:before:w-full lg:text-[1rem]' href='/'>Home</a>
	  </li>
	  <li class='flex'>
	    <a class='relative text-[0.75rem] font-bold uppercase before:absolute before:-bottom-1 before:h-1 before:w-0 before:bg-blueDianne before:duration-500 hover:before:w-full lg:text-[1rem]' href='/episodes/conversations-in-the-twyn-app'>Conversations</a>
	  </li>
        </ul>
        <a
          href="https://lse.onelink.me/Hzcj"
          className="download-link flex h-10 items-center rounded-full bg-white px-4 text-[0.75rem] font-bold uppercase text-blueDianne duration-200 md:h-14 md:px-6 md:text-[1rem] lg:h-16 lg:px-8 lg:text-[1.25rem] xl:h-20 xl:px-10 xl:text-[1.5rem] 2xl:h-[84px] hover-hover:hover:bg-white hover-hover:hover:text-blueDianne"
        >
          Download
        </a>
      </div>
    </header>
  )
}

export default SubpageHeader
