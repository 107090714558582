import React from 'react'
import classNames from 'classnames'

const Section = React.forwardRef(({className, children, ...restProps}, ref) => (
  <section ref={ref} className={classNames(className)} {...restProps}>
    {children}
  </section>
))

export default Section
