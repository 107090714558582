import React from 'react'
import ReactDOM from 'react-dom/client'
import { storyblokInit, apiPlugin } from '@storyblok/react';

import './styles/index.css'
import App from './App'
import TwynPage from './components/twyn-page'

storyblokInit({
  accessToken: 'm8EobPY5I94Yroz02ux1yQtt',
  use: [apiPlugin],
  components: {
    twynPage: TwynPage
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
